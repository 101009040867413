<template>
  <div>
    <CRow>
      <CCol col="12">
       <h5><strong>SMS</strong></h5>
      </CCol>
      <CCol col="12">
        <WidgetsDropdown/>
      </CCol>
    </CRow>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Cantidad Mensajes Enviados Por Dia</h4>
            <div class="small text-muted">Ultimos 25 Dias</div>
          </CCol>
          <!--<CCol sm="7" class="d-none d-md-block">
            <CButton color="primary" class="float-right">
              <CIcon name="cil-cloud-download"/>
            </CButton>
            <CButtonGroup class="float-right mr-3">
              <CButton
                color="outline-secondary"
                v-for="(value, key) in ['Dia', 'Mes', 'Año']"
                :key="key"
                class="mx-0"
                :pressed="value === selected ? true : false"
                @click="selected = value"
              >
                {{value}}
              </CButton>
            </CButtonGroup>
          </CCol>-->
        </CRow>
        <ChartMensajes :dataChartMensajes="dataChartMensajes" style="height:300px;margin-top:40px;"/>
      </CCardBody>
    </CCard>
    <!--<WidgetsBrand/>-->
    <CRow>
      <CCol col="12">
       <h5><strong>MAIL</strong></h5>
      </CCol>
      <CCol col="12">
        <WidgetsDropdownMail/>
      </CCol>
    </CRow>
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" class="card-title mb-0">Cantidad Mensajes Enviados Por Dia</h4>
            <div class="small text-muted">Ultimos 25 Dias</div>
          </CCol>
        </CRow>
        <ChartMensajesMail :dataChartMensajes="dataChartMensajesMail" style="height:300px;margin-top:40px;"/>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import ChartMensajes from './charts/ChartMensajes.vue'
import WidgetsDropdown from './widgets/WidgetsDropdown.vue'
import WidgetsBrand from './widgets/WidgetsBrand.vue'
import WidgetsDropdownMail from '../view_mails/widgets/WidgetsDropdownMail.vue'
import ChartMensajesMail from '../view_mails/widgets/ChartMensajesMail.vue'

export default {
  name: 'Dashboard',
  components: {
    ChartMensajes,
    WidgetsDropdown,
    WidgetsBrand,
    WidgetsDropdownMail,
    ChartMensajesMail
  },
  data () {
    return {
      selected: 'Mes',
      dataChartMensajes:{},
      dataChartMensajesMail:{},
    }
  },
  methods: {
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    obtenerDatosWidget(){
      this.$http.get('/sms/chart1').then(response => {
            this.dataChartMensajes = response.data;
        }).catch((e)=>{

        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    obtenerDatosWidgetMail(){
      this.$http.get(this.$httpUrlMail+'/mail/chart1',{withCredentials:false}).then(response => {
            this.dataChartMensajesMail = response.data;
        }).catch((e)=>{

        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    }
  },
  mounted () {
    setTimeout(() =>{
      this.obtenerDatosWidget();
      this.obtenerDatosWidgetMail();
    },100)
  }
}
</script>
