<template>
  <CChartLine
    :datasets="defaultDatasets"
    :options="defaultOptions"
    :labels="dataChartMensajes['dias']"
  />
  
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'ChartMensajesMail',
  components: {
    CChartLine
  },
  data () {
    return {}
  },
  props:{
    dataChartMensajes: Object
  },
  computed: {
    defaultDatasets () {
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'

      const data1 = this.dataChartMensajes['enviados'];
      const data2 = this.dataChartMensajes['total'];
      return [
        {
          label: 'Cantidad de Mensajes Enviados',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: data1
        },
        {
          label: 'Ceditos',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: data2
        },
      ]
    },
    defaultOptions () {
      let maxTicksLimit = this.dataChartMensajes['maxTicksLimit'] == undefined ? 10 : this.dataChartMensajes['maxTicksLimit'];
      let maximo_grafica = this.dataChartMensajes['maximo_grafica'] == undefined ? 500 : parseInt(this.dataChartMensajes['maximo_grafica']);
      maximo_grafica = maximo_grafica + (maximo_grafica/2);
      let stepSize = this.dataChartMensajes['stepSize'] == undefined ? Math.ceil(500 / 10) : Math.ceil(maximo_grafica/maxTicksLimit);
      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: maxTicksLimit,//10,
              stepSize: stepSize,//Math.ceil(500 / 10),
              max: maximo_grafica,//500
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>
