<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="primary" :header="enviados" text="Total Mensajes Enviado">
        <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <!--<CCol sm="6" lg="3">
      <CWidgetDropdown
        color="warning"
        :header="pendientes"
        text="Mensajes Pendientes"
      >
        <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown
        color="success"
        :header="saldo"
        text="Creditos"
      >
        <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template>
      </CWidgetDropdown>
    </CCol>-->
  </CRow>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from '../../views/charts/index.js'//'../charts/index.js'

export default {
  name: 'WidgetsDropdownMail',
  components: { CChartLineSimple, CChartBarSimple },
  data () {
    return {
        enviados: null,
        pendientes: null,
        subcuentas:null,
        saldo: null,
    }
  },
  methods: {
    obtenerDatosWidget(){
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.get(this.$httpUrlMail+'/mail/dashboard',{withCredentials:false}).then(response => {
          let data = response.data;
          this.enviados = `${this.creditosConversorMoneda(data.enviados,2)}`
          this.pendientes= `${this.creditosConversorMoneda(data.pendientes,2)}`
          this.saldo= `${this.creditosConversorMoneda(data.saldo_actual)}`
        }).catch((e)=>{

        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    creditosConversorMoneda(value,tipo = 1){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      if (tipo == 1) {
         return (formatterDolar.format(value)).replace('$','');
      }else{
        let numero = (formatterDolar.format(value)).replace('$','');
        return numero.replace('.00','')
      }
     
    }
  },
  mounted () {
    setTimeout(() =>{
      this.obtenerDatosWidget();
    },100)
  }
}
</script>
